.content-head {
  padding-top: 100px;
  margin-bottom: 30px;

  @include m768() {
    padding-top: 50px;
  }
  @include m425() {
    margin-bottom: 0;
  }
}
.content-head__title {
  font-size: 60px;
  font-weight: 900;
  line-height: 72px;
  margin-bottom: 20px;
  letter-spacing: 1.2px;
  color: #571b0f;

  @include baz(italic);
  @include m768() {
    font-size: 40px;
    line-height: 44px;
  }
  @include m425() {
    font-size: 28px;
    line-height: 30px;
  }
}
.content-head__text {
  font-size: 24px;
  line-height: 1.2;
  display: inline-block;
  padding: 6px 0;
  color: #37432f;

  @include arial(regular);
  @include m768() {
    font-size: 18px;
    padding: 0;
  }
  @include m425() {
    font-size: 16px;
  }
}

//***** form-section
.content-form__section {
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: rgba(0,0,0,.3);
  }
  .container {
    position: relative;
    z-index: 10;
  }
}
.content-form__title {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 10px;
  color: #fff;

  @include arial(bolditalic);
  @include m680() {
    font-size: 24px;
    line-height: 28px;
  }
  @include m425() {
    font-size: 18px;
    line-height: 22px;
  }
}
.content-form__title--highlight {
  text-transform: uppercase;
  color: #ed1c24;
}


.content-form__form {
  display: flex;
  flex-direction: column;

  justify-content: center;
  flex-wrap: wrap;
}
.content-form__input-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  justify-content: center;
  justify-content: space-around;
  @include m680() {
    flex-direction: column;
  }
}
.content-form__input {
  input {
    font-size: 18px;
    line-height: 24px;
    min-width: 260px;
    margin-bottom: 25px;
    padding: 10px;
    text-align: center;
    color: #797574;
    border: none;
    border-radius: 24px;
    outline: none;

    @include arial(italic);
  @include m425() {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 5px 10px 3px 10px;
  }
  }
}
.content-form__input-submit {
  input {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 15px 50px;
    transition: .5s;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 30px;
    outline: none;
    background-color: #ed1c24;

    @include arial(bolditalic);
    @include m425() {
      font-size: 20px;
      padding: 10px 30px;
    }
    &:hover {
      transition: .5s;
      background-color: #d70d15;
    }
  }
}


.content-footer {
  .container {
    max-width: 650px;
    padding-bottom: 50px;
  }
}
.content-footer__title {
  font-size: 30px;
  margin: 10px 0;
  color: #37432f;

  @include arial(bold);
  @include m768() {
    font-size: 24px;
    line-height: 28px;
  }
  @include m425() {
    font-size: 20px;
    line-height: 24px;
  }
}
.content-footer__text {
  font-size: 24px;
  line-height: 30px;
  color: #37432f;

  @include arial(regular);
  @include m768() {
    font-size: 18px;
    line-height: 22px;
  }
  @include m425() {
    font-size: 16px;
line-height: 20px;
  }
}
