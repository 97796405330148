*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html {
  font-size: 14px;
  line-height: 1.2;
  box-sizing: border-box;

  @include arial(regular);
}
body {
  min-width: 320px;
  height: 100%;
  color: #3f3f3f;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
}
.wrapper {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  min-height: 100%;
  text-align: center;
  background: #d4f9ad url('../img/bg-main.jpg') 50% 0% no-repeat;
  // background-size: cover;

  justify-content: center;
}
.container {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 10px 20px;

  @include m425() {
    padding: 10px;
  }
}