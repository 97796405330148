@font-face {
  font-family: 'ArialMT';
  src: url('../fonts/ArialMT/ArialMT.eot');
  src: url('../fonts/ArialMT/ArialMT.woff') format('woff'),
  url('../fonts/ArialMT/ArialMT.ttf') format('truetype'),
  url('../fonts/ArialMT/ArialMT.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial-BoldMT';
  src: url('../fonts/Arial-BoldMT/Arial-BoldMT.eot');
  src: url('../fonts/Arial-BoldMT/Arial-BoldMT.woff') format('woff'),
  url('../fonts/Arial-BoldMT/Arial-BoldMT.ttf') format('truetype'),
  url('../fonts/Arial-BoldMT/Arial-BoldMT.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial-BoldItalicMT';
  src: url('../fonts/Arial-BoldItalicMT/Arial-BoldItalicMT.eot');
  src: url('../fonts/Arial-BoldItalicMT/Arial-BoldItalicMT.woff') format('woff'),
  url('../fonts/Arial-BoldItalicMT/Arial-BoldItalicMT.ttf') format('truetype'),
  url('../fonts/Arial-BoldItalicMT/Arial-BoldItalicMT.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial-ItalicMT';
  src: url('../fonts/Arial-ItalicMT/Arial-ItalicMT.eot');
  src: url('../fonts/Arial-ItalicMT/Arial-ItalicMT.woff') format('woff'),
  url('../fonts/Arial-ItalicMT/Arial-ItalicMT.ttf') format('truetype'),
  url('../fonts/Arial-ItalicMT/Arial-ItalicMT.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BazhanovC-Italic';
  src: url('../fonts/BazhanovC-Italic/BazhanovC-Italic.eot');
  src: url('../fonts/BazhanovC-Italic/BazhanovC-Italic.woff') format('woff'),
  url('../fonts/BazhanovC-Italic/BazhanovC-Italic.ttf') format('truetype'),
  url('../fonts/BazhanovC-Italic/BazhanovC-Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}